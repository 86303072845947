<template>
    <div>
        <div class="mainwidth">
            <div class="news-top">
                <div class="news-nav ">
                    <ul>
                        <li :class="{liactive:item.id === newsnavId}" v-for="item in newsnavTabs" :key="item.id" @click="onTab(item)">{{ item.label }}</li>
                    </ul>
                </div>
            </div>
            <div class="news-con" v-show="newsnavId === 1">
                <ul>
                    <li v-for="(item, i) in dList" :key="i">
                        <span class="sp-title">{{item.ENTERPRISE_NAME}}</span>
                        <span  class="sp-amount">{{item.QUOTA_E}}万元</span>
                        <span class="sp-time">{{item.ISSUED_DATE}}</span>
                    </li>
                </ul>
            </div>
            <div class="news-con" v-show="newsnavId === 2">
                <ul>
                    <li v-for="(sItem, si) in sList" :key="si">
                        <span class="sp-title">{{sItem.ENTERPRISE_NAME}}</span>
                        <span  class="sp-amount">{{sItem.RECEIVE_MONEY}}万元</span>
                        <span class="sp-time">{{sItem.R_DATE}}</span>
                    </li>
                </ul>
            </div>
            <div class="page-box">
                <a-pagination show-quick-jumper v-model:current="page" :total="total" @change="onChange" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            newsnavTabs: [
                {
                    label: '融资需求',
                    id: 1
                },
                {
                    label: '获得融资企业',
                    id: 2
                }
            ],
            newsnavId: 1,
            page: 1,
            total: 0,
            dList: [],
            sList: []
        }
    },
    methods: {
        onChange (pageNumber) {
            this.page = pageNumber
            if (this.newsnavId == 1) {
                this.getDemandList()
            } else if (this.newsnavId == 2) {
                this.getLinkEnterProcOkList()
            }
        },
        onTab (item) {
            this.newsnavId = item.id
            this.page = 1
            this.total = 0
            if (item.id == 1) {
                this.getDemandList()
            } else if (item.id == 2) {
                this.getLinkEnterProcOkList()
            }
        },
        // 获取需求列表
        getDemandList () {
            this.$store.dispatch('getDemandList', { showCount: 10, currentPage: this.page }).then(res => {
                if (res.result === 'success') {
                    this.dList = res.varList
                    this.total = res.page.totalResult
                } else {
                    this.dList = []
                }
            })
        },
        // 获取成功对接列表
        getLinkEnterProcOkList () {
            this.$store.dispatch('getLinkEnterProcOkList', { showCount: 10, currentPage: this.page, tm: new Date().getTime() }).then(res => {
                if (res.result === 'success') {
                    this.sList = res.varList
                    this.total = res.page.totalResult
                } else {
                    this.total = 0
                    this.sList = []
                }
            })
        }
    },
    created () {
        if (this.$route.query.tab == 2) {
            this.onTab(this.newsnavTabs[1])
        } else if (this.$route.query.tab == 1) {
            this.onTab(this.newsnavTabs[0])
        }
        // 获取需求列表
        this.getDemandList()
    }
}
</script>

<style lang="less" scoped>
.news-top{
    margin-top: 30px;
    padding: 15px 10px;
    background-color:@color-ff;
    border-radius: 5px;
    box-shadow: 0px 0px 10px #929292;
    position: relative;
    font-size: @font-xl;
    .news-nav{
        display: flex;
        justify-content: space-between;
        align-items: center;
        ul{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 0;
            li{
                height: 40px;
                line-height: 40px;
                padding: 0 30px;
                border: 1px solid @color-blue;
                margin: 0 20px;
                border-radius: 20px;
                cursor: pointer;
            }
            .liactive{
                color: @color-ff;
                background-color: @color-blue;
            }
            li:hover{
                color: @color-ff;
                background-color: @color-blue;
            }
        }
    }
}
.news-top::before{
    content: '';
    position: absolute;
    left: -3px;
    top: 15px;
    width: 6px;
    height: 40px;
    background: @color-blue; /* 标准语法 */
}
.news-top::after{
    content: '';
    position: absolute;
    right: -3px;
    top: 15px;
    width: 6px;
    height: 40px;
    background:@color-blue; /* 标准语法 */
}
.news-con{
    padding: 20px;
    margin-top: 30px;
    margin-bottom: 30px;
    background-color: @color-ff;
    border-radius: 5px;
    box-shadow: 0px 0px 10px #929292;
    ul{
        li{
            display: flex;
            justify-content: space-between;
            padding: 10px 0;
            border-bottom: @border-base;
            span{
                display: block;
                height: 36px;
                line-height: 36px;
                font-size: 16px;;
                color: @color-base;
            }
            .sp-title{
                width: 40%;

            }
            .sp-apply{
                width: 20%;

            }
            .sp-amount{
                width: 20%;
                color: @color-blue;
                font-weight: bold;

            }
            .sp-time{
                width: 10%;
                text-align: right;

            }
        }
        li:first-child{
            padding-top: 0;
        }
    }
}
.page-box{
    padding: 15px 20px;
    background-color: @color-ff;
    border-radius: 5px;
    box-shadow: 0px 0px 10px #929292;
    margin-bottom: 30px;
    position: relative;
    .ant-pagination{
        display: flex;
        justify-content: center;
    }
}
.page-box::before{
    content: '';
    position: absolute;
    left: -3px;
    top: 11px;
    width: 6px;
    height: 40px;
    background: @color-blue;
}
.page-box::after{
    content: '';
    position: absolute;
    right: -3px;
    top: 11px;
    width: 6px;
    height: 40px;
    background: @color-blue;
}
</style>
